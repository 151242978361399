import type { FirebaseApp } from "firebase/app";
import {
  httpsCallable,
  getFunctions,
  HttpsCallableResult,
  HttpsCallableOptions,
} from "firebase/functions";

import { Affiliation, ArtistSignup } from "./types";
import { ArtistGroupRole } from "../artists";

export const AccountRequestActions = [
  "init",
  "verify",
  "artist",
  "password",
  "add-user",
  "request-access",
  "send-access-email",
  "approval",
] as const;
export type AccountAction = (typeof AccountRequestActions)[number];

export interface AccountInitRequest {
  action: Extract<AccountAction, "init">;
  email: string;
  urlParams?: string;
}

export const AccountInitReponseStatuses = [
  "error",
  "require-resume",
  "require-login",
  "ok",
] as const;
export type AccountInitReponseStatus =
  (typeof AccountInitReponseStatuses)[number];

export type AccountInitReponse =
  | {
    status: Exclude<AccountInitReponseStatus, "ok" | "error">;
  }
  | {
    status: Extract<AccountInitReponseStatus, "ok">;
    signupId: string;
  }
  | {
    status: Extract<AccountInitReponseStatus, "error">;
    message?: string;
  };

export interface AccountVerifyBaseRequest {
  action: Extract<AccountAction, "verify">;
  signupId: string;
  code?: string;
}

export type AccountArtistRequest = {
  action: Extract<AccountAction, "artist">;
  signupId: string;
} & ({ spotifyArtistId: string } | { artistName: string });

export interface AccountArtistResponse {
  status: "ok" | "error" | "exists" | "taken";
  size?: ArtistSignup["size"];
}

export type AccountVerifyRequest = AccountVerifyBaseRequest &
  ({ email: string } | { phone: string });

export interface AccountVerifyResponse {
  status:
  | "error"
  | "code-sent"
  | "code-confirmed"
  | "code-invalid"
  | "throttled";
  token?: string;
}

export interface AccountPasswordRequest {
  action: Extract<AccountAction, "password">;
  signupId: string;
  password: string;
}

export type AccountPasswordResponse =
  | {
    status: "error";
    message?: string;
  }
  | {
    status: "ok";
    token: string;
  };

export interface AccountAddUserRequest {
  action: Extract<AccountAction, "add-user">;
  artistGroupId: string;
  email: string;
  roles?: ArtistGroupRole[];
  affiliation: Affiliation;
}

export const AccountRequestAccessRequestStatues = [
  "approve",
  "reject",
] as const;
export type AccountRequestAccessRequestStatus =
  (typeof AccountRequestAccessRequestStatues)[number];

export interface AccountRequestAccessRequest {
  action: Extract<AccountAction, "request-access">;
  signupId: string;
  status: AccountRequestAccessRequestStatus;
  reason?: string;
  admin?: boolean;
}

export type AccountRequestAccessReponse = {
  status: "ok" | "error";
  message?: string;
};

export interface AccountSendAccessEmailRequest {
  action: Extract<AccountAction, "send-access-email">;
  signupId: string;
}

export type AccountSendAccessEmailReponse = {
  status: "ok" | "error";
  message?: string;
};

export interface AccountApprovalRequest {
  action: Extract<AccountAction, "approval">;
  signupId: string;
  status: Extract<ArtistSignup["status"], "approved" | "rejected">;
  reason?: string;
}

export interface AccountApprovalReponse {
  status: "approved" | "rejected" | "failed";
  message?: string;
}

/*
export const isAccountAddUserRequest = (
  req: unknown,
): req is AccountAddUserRequest => {
  if (typeof req !== "object" || req === null) {
    return false;
  }
  if ("roles" in req && (req.roles === undefined || Array.isArray(req.roles))) {
    req.roles = onlyTypeArray(req.roles || [], ArtistGroupRoles);
  }

  return (
    "action" in req &&
    req.action === "add-user" &&
    "affiliation" in req &&
    typeof req.affiliation === "string" &&
    Affiliations.includes(req.affiliation) &&
    "artistGroupId" in req &&
    !!req.artistGroupId &&
    typeof req.artistGroupId === "string" &&
    "email" in req &&
    !!req.email &&
    typeof req.email === "string"
  );
};
*/

export type AccountAddUserReponse =
  | {
    success: false;
    error: string;
  }
  | {
    success: true;
    signupId: string;
    isNew: boolean;
  };

export type AccountRequest =
  | AccountInitRequest
  | AccountVerifyRequest
  | AccountArtistRequest
  | AccountPasswordRequest
  | AccountAddUserRequest
  | AccountRequestAccessRequest
  | AccountSendAccessEmailRequest
  | AccountApprovalRequest;

function account(
  req: AccountInitRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountInitReponse>>;
function account(
  req: AccountVerifyRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountVerifyResponse>>;
function account(
  req: AccountArtistRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountArtistResponse>>;
function account(
  req: AccountPasswordRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountPasswordResponse>>;
function account(
  req: AccountAddUserRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountAddUserReponse>>;
function account(
  req: AccountRequestAccessRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountRequestAccessReponse>>;
function account(
  req: AccountSendAccessEmailRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountSendAccessEmailReponse>>;
function account(
  req: AccountApprovalRequest,
  app?: FirebaseApp,
): Promise<HttpsCallableResult<AccountApprovalReponse>>;
function account(req: unknown, app?: FirebaseApp): Promise<unknown> {
  return httpsCallable(getFunctions(app, "us-west2"), "signup-account")(req);
}

export interface SpotifyResponse<T = string> {
  token: string;
  expires: T;
}

const spotify = async (app?: FirebaseApp, options?: HttpsCallableOptions) =>
  httpsCallable<void, SpotifyResponse>(
    getFunctions(app, "us-central1"),
    "signup-spotify",
    options,
  )();

export const functions = {
  account,
  spotify,
};
